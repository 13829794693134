define("manage/components/manage/text", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer @title={{@title}} @isDisabled={{@isDisabled}} @editablePanel={{@editablePanel}}
    @onEdit={{@onEdit}} @onSave={{@onSave}} @value={{@value}} @onCancel={{@onCancel}} class='container-group-text'
    ...attributes>
    <:view>
      {{#if (and (not @value) @emptyError)}}
        {{#if @isMainValue}}
          <div class='alert alert-info' role='alert'>
            {{@emptyError}}
          </div>
        {{else}}
          <span class='text-muted'>{{@emptyError}}</span>
        {{/if}}
      {{else}}
        {{#if @renderAsCode}}
          <code><pre>{{@value}}</pre></code>
        {{else}}
          <p>{{@value}}</p>
        {{/if}}
      {{/if}}
    </:view>
  
    <:edit as | manager |>
      <div class='input-group' {{did-insert this.setupValue}}>
        {{#if @options}}
          <Input::Select class='form-control' @list={{@options}} @value={{manager.value}} @onChange={{manager.change}} />
        {{else if @useTextArea}}
          <Textarea class='form-control' @value={{manager.value}} placeholder={{@placeholder}} rows='6' />
        {{else}}
          <Input class='form-control' @type={{@type}} @value={{manager.value}} placeholder={{@placeholder}} />
        {{/if}}
      </div>
    </:edit>
  </Manage::FieldContainer>
  */
  {
    "id": "PP7bHVQt",
    "block": "[[[8,[39,0],[[24,0,\"container-group-text\"],[17,1]],[[\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@value\",\"@onCancel\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,7]],null],[30,9]],null],[[[41,[30,10],[[[1,\"        \"],[10,0],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[1,\"\\n          \"],[1,[30,9]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"text-muted\"],[12],[1,[30,9]],[13],[1,\"\\n\"]],[]]]],[]],[[[41,[30,11],[[[1,\"        \"],[10,\"code\"],[12],[10,\"pre\"],[12],[1,[30,7]],[13],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,[30,7]],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[11,0],[24,0,\"input-group\"],[4,[38,11],[[30,0,[\"setupValue\"]]],null],[12],[1,\"\\n\"],[41,[30,13],[[[1,\"        \"],[8,[39,12],[[24,0,\"form-control\"]],[[\"@list\",\"@value\",\"@onChange\"],[[30,13],[30,12,[\"value\"]],[30,12,[\"change\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,14],[[[1,\"        \"],[8,[39,13],[[24,0,\"form-control\"],[16,\"placeholder\",[30,15]],[24,\"rows\",\"6\"]],[[\"@value\"],[[30,12,[\"value\"]]]],null],[1,\"\"]],[]],[[[1,\"        \"],[8,[39,14],[[24,0,\"form-control\"],[16,\"placeholder\",[30,15]]],[[\"@type\",\"@value\"],[[30,16],[30,12,[\"value\"]]]],null],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[12]]]]]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@onEdit\",\"@onSave\",\"@value\",\"@onCancel\",\"@emptyError\",\"@isMainValue\",\"@renderAsCode\",\"manager\",\"@options\",\"@useTextArea\",\"@placeholder\",\"@type\"],false,[\"manage/field-container\",\":view\",\"if\",\"and\",\"not\",\"div\",\"span\",\"code\",\"pre\",\"p\",\":edit\",\"did-insert\",\"input/select\",\"textarea\",\"input\"]]",
    "moduleName": "manage/components/manage/text.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageTextComponent = _exports.default = (_class = class ManageTextComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
    setupValue() {
      this.value = this.args.value;
    }
    updateValue(newValue) {
      if (typeof newValue.id == 'string') {
        this.value = newValue.id;
        return;
      }
      this.value = newValue;
    }
    save() {
      console.log('!!!!!', this.value);
      return this.args.onSave(this.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageTextComponent);
});