define("spaces-view/components/picture-full-frame", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _runloop, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="d-none" {{did-insert this.setup}}></div>
  
  {{#if this.pictureTarget}}
    {{#in-element this.pictureTarget}}
      <div
        class="page-col-gallery page-col-gallery-modal page-col-gallery-picture d-flex flex-column
          {{if this.isPicturePresentable 'presentable'}}
          {{if this.isPicturePresenting 'presenting'}}"
        {{on "transitionend" this.checkHidePicture}}
      >
        <div class="gallery-header p-2 mb-2 d-flex">
          <button
            type="button"
            class="btn btn-dark btn-close-modal"
            {{on "click" this.prepareHidePicture}}
          >
            <FaIcon @icon="times" />
          </button>
          <div class="title p-2 flex-fill">
            {{@title}}
          </div>
        </div>
  
        <div
          class="picture-container d-flex flex-column justify-content-center align-items-center"
        >
          <PictureWithOptions
            class="page-col-picture cursor-pointer"
            @name={{@value.name}}
            @meta={{@value.meta}}
            @src={{@value.picture}}
            @hash={{@value.hash}}
            @options={{this.largePictureValues}}
          />
        </div>
      </div>
    {{/in-element}}
  {{/if}}
  */
  {
    "id": "bB7Uo2Su",
    "block": "[[[11,0],[24,0,\"d-none\"],[4,[38,1],[[30,0,[\"setup\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"pictureTarget\"]],[[[40,[[[1,\"    \"],[11,0],[16,0,[29,[\"page-col-gallery page-col-gallery-modal page-col-gallery-picture d-flex flex-column\\n        \",[52,[30,0,[\"isPicturePresentable\"]],\"presentable\"],\"\\n        \",[52,[30,0,[\"isPicturePresenting\"]],\"presenting\"]]]],[4,[38,5],[\"transitionend\",[30,0,[\"checkHidePicture\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"gallery-header p-2 mb-2 d-flex\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-dark btn-close-modal\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"prepareHidePicture\"]]],null],[12],[1,\"\\n          \"],[8,[39,7],null,[[\"@icon\"],[\"times\"]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"title p-2 flex-fill\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"picture-container d-flex flex-column justify-content-center align-items-center\"],[12],[1,\"\\n        \"],[8,[39,8],[[24,0,\"page-col-picture cursor-pointer\"]],[[\"@name\",\"@meta\",\"@src\",\"@hash\",\"@options\"],[[30,2,[\"name\"]],[30,2,[\"meta\"]],[30,2,[\"picture\"]],[30,2,[\"hash\"]],[30,0,[\"largePictureValues\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,4],[[30,0,[\"pictureTarget\"]]],null]]],[]],null]],[\"@title\",\"@value\"],false,[\"div\",\"did-insert\",\"if\",\"in-element\",\"-in-el-null\",\"on\",\"button\",\"fa-icon\",\"picture-with-options\"]]",
    "moduleName": "spaces-view/components/picture-full-frame.hbs",
    "isStrictMode": false
  });
  let PictureFulllframeComponent = _exports.default = (_class = class PictureFulllframeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fullscreen", _descriptor, this);
      _initializerDefineProperty(this, "pictureTarget", _descriptor2, this);
      _initializerDefineProperty(this, "isPicturePresentable", _descriptor3, this);
      _initializerDefineProperty(this, "isPicturePresenting", _descriptor4, this);
    }
    get largePictureValues() {
      return {
        sizing: 'auto',
        classes: [],
        attributions: 'below'
      };
    }
    willDestroy() {
      this.fullscreen.unfreezeBody();
    }
    setup() {
      this.pictureTarget = document.querySelector('#picture-target');
      (0, _runloop.later)(() => {
        this.fullscreen.freezeBody();
        this.isPicturePresentable = true;
      }, 1);
    }
    hidePicture() {
      this.isPictureVisible = false;
      this.isPicturePresentable = false;
      this.isPicturePresenting = false;
      this.args.onHide?.();
    }
    checkHidePicture() {
      if (!this.isPicturePresentable) {
        return this.hidePicture();
      }
      this.isPicturePresenting = true;
    }
    prepareHidePicture() {
      this.isPicturePresentable = false;
      (0, _runloop.later)(() => {
        const modal = this.pictureTarget.querySelector('.page-col-gallery-picture');
        if (!modal) {
          return;
        }
        const style = getComputedStyle?.(modal);
        if (style.opacity == 0) {
          this.checkHidePicture();
        }
      }, 10);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pictureTarget", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isPicturePresentable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isPicturePresenting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hidePicture", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hidePicture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkHidePicture", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkHidePicture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prepareHidePicture", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "prepareHidePicture"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PictureFulllframeComponent);
});