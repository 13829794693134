define("manage/components/manage/default-models", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "models/transforms/default-models", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _defaultModels, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Manage::FieldContainer
    @title={{@title}}
    @isDisabled={{@isDisabled}}
    @isSaveDisabled={{this.isDisabled}}
    @editablePanel={{@editablePanel}}
    @value={{@value}}
    @onEdit={{@onEdit}}
    @onSave={{@onSave}}
    @onCancel={{@onCancel}}
    class="manage-default-models"
    ...attributes
  >
    <:view>
      {{#each this.pairs as |pair|}}
        <div class="model-value">
          <div class="model">
            {{pair.name}}
          </div>
          <div class="value">
            {{pair.value}}
          </div>
        </div>
      {{/each}}
    </:view>
  
    <:edit as | manager |>
      <Input::DefaultModels @value={{manager.value}} @team={{@team}} @onChange={{manager.change}} />
    </:edit>
  </Manage::FieldContainer>
  
  <div class="d-none" {{did-insert this.setup}} {{did-update this.setup @value}}></div>
  */
  {
    "id": "X4PDEbQb",
    "block": "[[[8,[39,0],[[24,0,\"manage-default-models\"],[17,1]],[[\"@title\",\"@isDisabled\",\"@isSaveDisabled\",\"@editablePanel\",\"@value\",\"@onEdit\",\"@onSave\",\"@onCancel\"],[[30,2],[30,3],[30,0,[\"isDisabled\"]],[30,4],[30,5],[30,6],[30,7],[30,8]]],[[\"view\",\"edit\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"pairs\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"model-value\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"model\"],[12],[1,\"\\n          \"],[1,[30,9,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n          \"],[1,[30,9,[\"value\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[9]],null],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,6],null,[[\"@value\",\"@team\",\"@onChange\"],[[30,10,[\"value\"]],[30,11],[30,10,[\"change\"]]]],null],[1,\"\\n  \"]],[10]]]]],[1,\"\\n\\n\"],[11,0],[24,0,\"d-none\"],[4,[38,7],[[30,0,[\"setup\"]]],null],[4,[38,8],[[30,0,[\"setup\"]],[30,5]],null],[12],[13]],[\"&attrs\",\"@title\",\"@isDisabled\",\"@editablePanel\",\"@value\",\"@onEdit\",\"@onSave\",\"@onCancel\",\"pair\",\"manager\",\"@team\"],false,[\"manage/field-container\",\":view\",\"each\",\"-track-array\",\"div\",\":edit\",\"input/default-models\",\"did-insert\",\"did-update\"]]",
    "moduleName": "manage/components/manage/default-models.hbs",
    "isStrictMode": false
  });
  let ManageDefaultModelsComponent = _exports.default = (_class = class ManageDefaultModelsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "map", _descriptor2, this);
      _initializerDefineProperty(this, "campaign", _descriptor3, this);
      _initializerDefineProperty(this, "calendar", _descriptor4, this);
      _initializerDefineProperty(this, "newsletter", _descriptor5, this);
    }
    get pairs() {
      const result = [];
      for (const name of _defaultModels.defaultModelNames) {
        result.push({
          name,
          value: this[name]?.title ?? 'not set',
          model: this[name]
        });
      }
      return result;
    }
    async setup() {
      const value = this.args.value;
      for (const name of _defaultModels.defaultModelNames) {
        if (value?.[name]) {
          let resolvedValue = this.store.peekRecord(name, value[name]);
          if (!resolvedValue) {
            try {
              resolvedValue = await this.store.findRecord(name, value[name]);
            } catch (err) {
              console.error(err);
            }
          }
          this[name] = resolvedValue;
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "map", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "campaign", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendar", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "newsletter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageDefaultModelsComponent);
});