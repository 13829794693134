define("spaces-view/components/view/event/dates", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "luxon", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _luxon, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <View::Base::SourceRecord @isEditor={{@isEditor}} @model={{@model}} @value={{@value}} @onLoad={{this.loaded}}>
    <div class="page-col-event-dates">
      {{#each this.niceEntries as | entry |}}
        <TextWithOptions @tag="div" class="entry" @deviceSize={{@deviceSize}} @options={{@value.data.style}}>{{!
          }}<MarkDown @value={{capitalize entry}}/>{{!
        }}</TextWithOptions>
      {{/each}}
    </div>
  </View::Base::SourceRecord>
  */
  {
    "id": "p2VJuZla",
    "block": "[[[8,[39,0],null,[[\"@isEditor\",\"@model\",\"@value\",\"@onLoad\"],[[30,1],[30,2],[30,3],[30,0,[\"loaded\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"page-col-event-dates\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"niceEntries\"]]],null]],null],null,[[[1,\"      \"],[8,[39,4],[[24,0,\"entry\"]],[[\"@tag\",\"@deviceSize\",\"@options\"],[\"div\",[30,5],[30,3,[\"data\",\"style\"]]]],[[\"default\"],[[[[8,[39,5],null,[[\"@value\"],[[28,[37,6],[[30,4]],null]]],null]],[]]]]],[1,\"\\n\"]],[4]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@isEditor\",\"@model\",\"@value\",\"entry\",\"@deviceSize\"],false,[\"view/base/source-record\",\"div\",\"each\",\"-track-array\",\"text-with-options\",\"mark-down\",\"capitalize\"]]",
    "moduleName": "spaces-view/components/view/event/dates.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let PageColEventDatesComponent = _exports.default = (_class = class PageColEventDatesComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "record", _descriptor2, this);
    }
    addDateKeys(prefix, dest, date) {
      dest[`${prefix}Time`] = date.toLocaleString(_luxon.DateTime.TIME_SIMPLE);
      dest[`${prefix}Date`] = date.toLocaleString(_luxon.DateTime.DATE_MED);
      dest[`${prefix}Y`] = date.toFormat('yyyy');
      dest[`${prefix}M`] = date.toFormat('MMMM');
      dest[`${prefix}D`] = date.toFormat('d');
      dest[`${prefix}DWeek`] = date.toFormat('EEEE');
    }
    toNiceString(entry) {
      let vars = {};
      this.addDateKeys('begin', vars, entry.begin);
      this.addDateKeys('end', vars, entry.end);
      if (entry.intervalEnd) {
        this.addDateKeys('intervalEnd', vars, entry.intervalEnd);
      }
      let intervalT = `${vars.beginD} ${vars.beginM}` == `${vars.endD} ${vars.endM}` ? `event_date.interval_same_day` : `event_date.interval_days_${entry.repetition}`;
      return this.intl.t(`event_date.repeats_${entry.repetition}`, vars) + ', ' + this.intl.t(intervalT, vars);
    }
    get niceEntries() {
      return this.record?.entries?.map(a => this.toNiceString(a));
    }
    loaded(record) {
      this.record = record;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "record", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loaded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loaded"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageColEventDatesComponent);
});