define("spaces/components/editor/event/location", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='manage-editors-event-dates' ...attributes>
    <Input::Options::ModelItem @space={{@space}} @value={{this.source}} @onChange={{this.changeSource}} @model="event" />
  
    <Editor::Base::CardOptions @value={{this.cardOptions}} @onChange={{this.changeCardOptions}} @space={{@space}} />
  </div>
  */
  {
    "id": "p0/XF6d2",
    "block": "[[[11,0],[24,0,\"manage-editors-event-dates\"],[17,1],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@space\",\"@value\",\"@onChange\",\"@model\"],[[30,2],[30,0,[\"source\"]],[30,0,[\"changeSource\"]],\"event\"]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@value\",\"@onChange\",\"@space\"],[[30,0,[\"cardOptions\"]],[30,0,[\"changeCardOptions\"]],[30,2]]],null],[1,\"\\n\"],[13]],[\"&attrs\",\"@space\"],false,[\"div\",\"input/options/model-item\",\"editor/base/card-options\"]]",
    "moduleName": "spaces/components/editor/event/location.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageEditorsEventLocationComponent = _exports.default = (_class = class ManageEditorsEventLocationComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_source", _descriptor, this);
      _initializerDefineProperty(this, "_cardOptions", _descriptor2, this);
    }
    get source() {
      if (this._source) {
        return this._source;
      }
      return this.value.data?.source ?? {};
    }
    get cardOptions() {
      if (this._cardOptions) {
        return this._cardOptions;
      }
      return this.value?.data ?? {};
    }
    get value() {
      return this.args.value ?? {};
    }
    triggerChange() {
      let value = {
        ...(this.value.data ?? {}),
        ...this.cardOptions,
        source: this.source
      };
      return this.args.onChange?.(value);
    }
    changeSource(value) {
      this._source = value;
      this.triggerChange();
    }
    changeCardOptions(value) {
      this._cardOptions = value;
      return this.triggerChange();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_source", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_cardOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeSource", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeCardOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeCardOptions"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageEditorsEventLocationComponent);
});