define("manage/lib/embedded-map-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmbeddedMapEditor = void 0;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class EmbeddedMapEditor {
    constructor({
      data
    }) {
      this.data = data;
    }
    render() {
      const container = document.createElement('div');
      container.innerHTML = `<div class="page-col-map-view placeholder">
      ${EmbeddedMapEditor.getEditorIcon('30px')}
    </div>

    <div class="embedded-map-editor mt-2 mb-3">
      <div class="row">
        <div class="col col-12 col-md-8">
          <div class="input-group mb-3">
            <span class="input-group-text">Map id</span>
            <input
              type="text"
              class="form-control map-editor-id"
              aria-label="map name"
            />
          </div>
        </div>

        <div class="col">
          <div class="col input-group mb-3">
            <span class="input-group-text">${EmbeddedMapEditor.heightIcon}</span>
            <input
              type="number"
              class="form-control cdx-search-field__input map-editor-height"
              placeholder="Height (px)"
            />
          </div>
        </div>
      </div>
    </div>`;
      container.querySelector('.map-editor-id').value = this.data?.source?.id ?? '';
      container.querySelector('.map-editor-height').value = this.data?.height ?? '';
      return container;
    }
    save(blockContent) {
      return {
        map: {
          mode: 'use a map',
          flyOverAnimation: false,
          featureHover: 'mouse pointer',
          featureSelection: 'open details in panel',
          enableMapInteraction: 'automatic',
          enablePinning: false,
          restrictView: true,
          showUserLocation: false,
          showZoomButtons: false,
          showBaseMapSelection: false
        },
        source: {
          useSelectedModel: false,
          model: 'map',
          id: blockContent.querySelector('.map-editor-id').value
        },
        sizing: 'fill',
        height: blockContent.querySelector('.map-editor-height').value
      };
    }
    static get isReadOnlySupported() {
      return false;
    }
    static get heightIcon() {
      return `<svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      class="height-icon"
    >
      <!-- Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com
      License - https://fontawesome.com/license (Commercial License) Copyright 2023
      Fonticons, Inc. -->
      <path
        d="M182.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L128 109.3V402.7L86.6 361.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96z"
      />
    </svg>`;
    }
    static getEditorIcon(size) {
      return `<div class="map-placeholder" style = "height: ${size}" > <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <!-- Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
      <path d="M384 476.1L192 421.2V35.9L384 90.8V476.1zm32-1.2V88.4L543.1 37.5c15.8-6.3 32.9 5.3 32.9 22.3V394.6c0 9.8-6 18.6-15.1 22.3L416 474.8zM15.1 95.1L160 37.2V423.6L32.9 474.5C17.1 480.8 0 469.2 0 452.2V117.4c0-9.8 6-18.6 15.1-22.3z" />
    </svg></div> `;
    }
    static get toolbox() {
      return {
        title: 'Embedded Map',
        icon: EmbeddedMapEditor.getEditorIcon('12px')
      };
    }
  }
  _exports.EmbeddedMapEditor = EmbeddedMapEditor;
});